<template>
  <T3Renderer />
</template>

<script setup lang="ts">
import type { T3CeBaseProps as _T3CeBaseProps, T3ContentElement as _T3ContentElement } from '@t3headless/nuxt-typo3'
import { h } from 'vue'

import { useT3DynamicCe, useT3DynamicComponent } from '#typo3/composables/useT3DynamicComponent'

type T3ContentElement = _T3ContentElement<_T3CeBaseProps>

interface T3RendererProps {
  content?: Array<T3ContentElement>
  frame?: boolean
}

const props = withDefaults(defineProps<T3RendererProps>(), {
  content: () => [],
  frame: true
})

const elementId = (element: T3ContentElement) => {
  return element.customId || `c${element.id}`
}

const renderComponent = (element: T3ContentElement, index: number) => {
  const component = useT3DynamicCe(element.type)

  return h(component, {
    uid: element.id,
    index,
    appearance: element.appearance,
    id: element.appearance.frameClass === 'none' ? elementId(element) : null,
    ...element.content
  })
}

const renderFrame = (element: T3ContentElement, index: number) => {
  const component = useT3DynamicComponent({ prefix: 'T3', type: 'Frame', mode: '' })

  return h(component,
    { ...element.appearance, id: elementId(element) },
    () => renderComponent(element, index)
  )
}

const T3Renderer = () => {
  return props.content.map((element, index) => {
    return props.frame && element.appearance.frameClass !== 'none'
      ? renderFrame(element, index)
      : renderComponent(element, index)
  })
}
</script>
